.CardCollection {
    padding: 1rem;
}

.CardCollection-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.CardCollection-card-container {
    width: 20rem;
    height: 30rem;
    margin: 1rem;
}
