/* Filters for changing an image's color, generated with the online tool:
 * https://codepen.io/sosuke/pen/Pjoqqp
 */

.Card {
    --filter-blue: invert(55%) sepia(28%) saturate(4785%) hue-rotate(158deg) brightness(91%) contrast(101%);
    --filter-red: invert(11%) sepia(83%) saturate(5734%) hue-rotate(359deg) brightness(78%) contrast(120%);
    --filter-green: invert(69%) sepia(45%) saturate(7308%) hue-rotate(65deg) brightness(98%) contrast(101%);
    --filter-yellow: invert(69%) sepia(17%) saturate(6397%) hue-rotate(21deg) brightness(94%) contrast(101%);
    --filter-purple: invert(11%) sepia(87%) saturate(5561%) hue-rotate(280deg) brightness(84%) contrast(114%);
}

.Card {
    padding: 1rem;
    border: 1px solid black;
    box-sizing: border-box;
    height: 100%;
    text-align: initial;
    border-radius: .5rem;
    overflow: hidden;
}

.Card-body {
}

.Card-color-0 {
    background-color: ivory;
}

.Card-color-1 {
    background-color: aliceblue;
}

.Card-color-2 {
    background-color: aquamarine;
}

.Card-color-3 {
    background-color: lavender;
}

.Card-color-4 {
    background-color: orange;
}

.Card-color-5 {
    background-color: lightcoral;
}

.Card-description {
}

.Card-image img {
    display: block;
    margin: 0.5rem auto;
}

.Card-filter-0 {
    filter: var(--filter-blue);
}

.Card-filter-1 {
    filter: var(--filter-red);
}

.Card-filter-2 {
    filter: var(--filter-green);
}

.Card-filter-3 {
    filter: var(--filter-yellow);
}

.Card-filter-4 {
    filter: var(--filter-purple);
}

.Card-header h1 {
    font-size: 1.2rem;
    margin: 0;
}

.Card-footer p {
    margin: auto;
}
