:root {
    --primary-color: #b30000;
    --soft-black: #555;
    --soft-white: #fff;
}

body {
    background-color: var(--soft-white);
    color: var(--soft-black);
}

.App {
  text-align: center;
}

.App-logo {
  height: 4rem;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.App-header {
    color: var(--primary-color);
    background-color: #e3e3e3;
    display: flex;
}

.App-collection {
    background-color: lavenderblush;
}

.App-footer {
    color: #eeeeee;
    background-color: #222222;
}
